export interface LogItem {
  // logId: string;
  readonly sortKey: number;
  readonly account: string;
  readonly action: string;
  readonly resource: string;
  readonly requester: string;
  readonly approver: string;
  readonly query: string;
  readonly status: string;
}

export interface RdsResponse {
  readonly items: readonly LogItem[];
  readonly pagination: {
    readonly current_page: number;
    readonly last_key: string;
    readonly next_page: number | null;
    readonly prev_page: number | null;
    readonly total_pages: number;
    readonly total_records: number;
  };
}

// export interface InfiniteRdsResponse {
//   pages: RdsResponse[];
//   pageParams: { page: number; lastKey: string | null }[];
// }

export const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
sevenDaysAgo.setHours(0, 0, 0, 0);

export function convertToStartOfDayUTC(selectedDate) {
  return new Date(
    Date.UTC(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      selectedDate.getHours(),
      selectedDate.getMinutes(),
      selectedDate.getSeconds(),
    ),
  );
}

export function convertToEndOfDayUTC(selectedDate) {
  return new Date(
    Date.UTC(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      selectedDate.getHours() + 23,
      selectedDate.getMinutes() + 59,
      selectedDate.getSeconds() + 59,
    ),
  );
}

export function formatDateToDDMMYYYY(date: Date): string {
  // Get day, month, and year in UTC
  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
  const year = date.getUTCFullYear();

  // Return formatted date string
  return `${day}/${month}/${year}`;
}
