import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/ui/table";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/ui/hover-card";
import { LogItem } from "@/types/types";
import { LoaderIcon } from "react-hot-toast";

function unixTimeToEuropeanDate(unixTime) {
  const milliseconds = unixTime * 1000;
  const dateObject = new Date(milliseconds);

  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  const hours = dateObject.getHours();
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();

  let minutesStr: string = "" + minutes,
    secondsStr: string = "" + seconds;
  if (minutes < 10) minutesStr = "0" + minutes;
  if (seconds < 10) secondsStr = "0" + seconds;

  const formattedDate = `${day}/${month}/${year}`;
  const formattedTime = `${hours}:${minutesStr}:${secondsStr}`;

  return `${formattedDate} ${formattedTime}`;
}

interface LogsTableProps {
  readonly data: readonly LogItem[];
}

export const LogsTable = ({ data }: LogsTableProps) => {
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "asc",
  });

  const limitDelta = 500;
  const initialLimit = 100;
  const [tableLimit, setTableLimit] = useState(initialLimit);

  const sortedData = useMemo(() => {
    if (!data) return []; // Handle the case where data is undefined
    console.log(typeof data);
    console.log(data);
    let sortableData = [...data];
    if (sortConfig.key === "date") {
      sortableData.sort((a, b) => {
        if (sortConfig.direction === "asc") {
          return +a.sortKey - +b.sortKey;
        } else {
          return +b.sortKey - +a.sortKey;
        }
      });
    }
    return sortableData.slice(0, tableLimit);
  }, [data, sortConfig, tableLimit]);

  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    // when filters change:
    // - hide all logs again
    // - scroll table to the top
    setTableLimit(initialLimit);
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  }, [data]);

  const showMore = () => {
    if (tableLimit <= sortedData.length) {
      setTableLimit((limit) => limit + limitDelta);
    }
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <ChevronUpIcon className="w-4 h-4 inline-block ml-1" />
      ) : (
        <ChevronDownIcon className="w-4 h-4 inline-block ml-1" />
      );
    }
    return null;
  };

  return (
    <>
      <Table className="min-w-full" ref={tableRef}>
        <TableCaption></TableCaption>
        <TableHeader className="sticky top-0 bg-white z-10">
          <TableRow>
            <TableHead
              className="cursor-pointer w-[120px]"
              onClick={() => handleSort("date")}
            >
              Date {getIcon("date")}
            </TableHead>
            <TableHead
              className="cursor-pointer"
              onClick={() => handleSort("account")}
            >
              Account {getIcon("account")}
            </TableHead>
            <TableHead onClick={() => handleSort("action")}>
              Action {getIcon("action")}
            </TableHead>
            <TableHead
              className="max-w-[200px]"
              onClick={() => handleSort("resource")}
            >
              Resource {getIcon("resource")}
            </TableHead>
            <TableHead>Requester</TableHead>
            <TableHead>Approver</TableHead>
            <TableHead>Query</TableHead>
            <TableHead>Status</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="overflow-y-scroll">
          {sortedData.map((item) => (
            <TableRow key={item.sortKey}>
              <TableCell>{unixTimeToEuropeanDate(item.sortKey)}</TableCell>
              <TableCell>{item.account}</TableCell>
              <TableCell>{item.action}</TableCell>
              <TableCell>{item.resource}</TableCell>
              <TableCell>{item.requester}</TableCell>
              <TableCell>{item.approver}</TableCell>
              <TableCell className="max-w-[500px] h-[36px]">
                <HoverCard>
                  <HoverCardTrigger className="flex align-middle mt-4">
                    <div className=" h-[36px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {item.query}
                    </div>
                  </HoverCardTrigger>
                  <HoverCardContent className="min-w-[500px] break-words whitespace-pre-wrap">
                    {item.query}
                  </HoverCardContent>
                </HoverCard>
              </TableCell>
              <TableCell>{item.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <ShowMoreLogs showMore={showMore} />
      </Table>
      <LoadingMessage loaded={tableLimit} total={data.length} />
    </>
  );
};

const LoadingMessage = ({ loaded, total }) => {
  const percent = Math.floor((loaded / total) * 100);
  const progressText = loaded < total ? `${percent}%` : "";
  const isLoading = loaded > total;
  return (
    <div className="flex justify-center items-center">
      {isLoading ? (
        <p>No more logs to display.</p>
      ) : (
        <>
          <p>Loading {progressText}</p> <LoaderIcon className="ml-2" />
        </>
      )}
    </div>
  );
};

const ShowMoreLogs = ({ showMore }) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            showMore();
            observer.disconnect();
          }
        });
      },
      { threshold: 0.1 },
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, [showMore]);

  return <div ref={elementRef} />;
};
