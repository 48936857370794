import { Button } from "@nexus/lib-react/dist/core/Button";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { allowedAuthProviders } from "./providers";

const providers = Object.entries(allowedAuthProviders);
type Providers = typeof providers;

function OneProvider({ providerId }: { readonly providerId: string }) {
  const navigate = useNavigate();

  useEffect(
    () => navigate(`/login/provider/${providerId}`),
    [navigate, providerId],
  );

  return (
    <>
      You should be redirect to {providerId} provider page. If not, please click
      <Link to={`/login/provider/${providerId}`}>this to redirect</Link>.
    </>
  );
}

function MultipleProviders({ providers }: { readonly providers: Providers }) {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex flex-col gap-2 bg-neutral-50 p-5">
        <h1 className="mb-3 text-xl">Login</h1>
        <ul className="flex flex-col gap-3">
          {providers.map(([pId, meta]) => {
            return (
              <li key={pId}>
                <Button>
                  <Link to={`/login/provider/${meta.id}`}>
                    {meta.verboseName}
                  </Link>
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export function Login(): React.ReactElement {
  const hasSingleProvider = providers.length == 1;
  if (hasSingleProvider) {
    const providerId = providers[0][0];
    return <OneProvider providerId={providerId} />;
  }

  return <MultipleProviders providers={providers} />;
}
