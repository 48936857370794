import { SideBar } from "@/components/SideBar";
import { TopBar } from "@/components/TopBar";
import { RandomLoader } from "@nexus/lib-react/dist/core/Loading/RandomLoader";
import { Suspense } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { useTimeout } from "react-use";

function Loading() {
  const [isReady] = useTimeout(120);
  return isReady() ? <RandomLoader /> : <Outlet />;
}

export const Layout = () => {
  const { state } = useNavigation();
  return (
    <div className="flex w-screen h-screen flex-col pb-4">
      <TopBar />
      <div className="mr-4 flex gap-4">
        {/* ml-16 because of fixed toolbar: w-12 + gap-4 */}
        <SideBar />
        <div className="ml-16 flex flex-1 overflow-auto">
          <Suspense fallback={<RandomLoader />}>
            {state === "idle" ? <Outlet /> : <Loading />}
          </Suspense>
        </div>
      </div>
    </div>
  );
};
