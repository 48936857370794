import { cn } from "@/utils";
import { ChevronDown, Delete, Search } from "lucide-react";
import { useEffect, useRef, useState } from "react";

export const filterByRemainingOptions = (filterSearch) => (f) => {
  if (filterSearch === "") {
    return true;
  }

  // case insensitive search, trim in order to ignore trailing whitespace
  return f?.toLowerCase().includes(filterSearch?.toLowerCase().trim());
};

interface FilterProps {
  readonly name: string;
  readonly filterArr: readonly string[];
  // eslint-disable-next-line no-unused-vars
  readonly setFilters: (filters: readonly string[]) => void;
}

export const Filter = ({ name, filterArr, setFilters }: FilterProps) => {
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [filterSearchText, setFilterSearchText] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [badgeFocused, setBadgeFocused] = useState<boolean>(false);
  const [menuFocused, setMenuFocused] = useState<boolean>(false);

  useEffect(() => {
    setFilters(selected);
    console.log(selected);
  }, [selected, setFilters]);

  useEffect(() => {
    setOpen(badgeFocused || menuFocused);
  }, [badgeFocused, menuFocused]);

  useEffect(() => {
    setFilterSearchText("");
  }, [open]);

  const remainingFilters = filterArr.filter(
    filterByRemainingOptions(filterSearchText),
  );

  return (
    <div
      tabIndex={0}
      className="relative flex items-center font-medium max-sm:mx-1"
    >
      <div
        tabIndex={0}
        onFocus={() => {
          setMenuFocused(true);
        }}
        onBlur={() => {
          setMenuFocused(false);
        }}
        className={`motion-safe:first-letter: z-50 flex min-w-[20rem] max-w-[90vw] flex-col overflow-hidden bg-white p-2 shadow-lg transition-all ${open ? "max-h-96 border" : "max-h-0 opacity-0"} -translate-x-2/3 absolute left-0 top-8`}
      >
        <div className="flex h-fit w-full flex-col">
          <FilterSearch text={filterSearchText} setText={setFilterSearchText} />
          <div className="my-1 flex items-center justify-between">
            <FilterCheckbox
              name="Select all"
              onSelect={() => {
                if (haveSameElements(selected, filterArr)) {
                  setSelected([]);
                } else {
                  setSelected(filterArr);
                }
              }}
              selected={haveSameElements(selected, filterArr)}
            />
            <div
              onClick={() => {
                setSelected([]);
              }}
              className="mr-2 cursor-pointer select-none text-right hover:underline"
            >
              Clear filter
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex-1 overflow-auto">
          {remainingFilters.map((f, i) => (
            <FilterCheckbox
              key={i}
              name={f}
              onSelect={() => {
                if (selected.includes(f)) {
                  setSelected(selected.filter((e) => e !== f));
                } else {
                  setSelected([...selected, f]);
                }
              }}
              selected={selected.includes(f)}
            />
          ))}
          <NoResults length={remainingFilters?.length} />
        </div>
      </div>
      <div
        tabIndex={0}
        onMouseDown={() => {
          setOpen(!badgeFocused);
          setBadgeFocused(!badgeFocused);
        }}
        onFocus={() => {
          setBadgeFocused(true);
        }}
        onBlur={() => {
          setBadgeFocused(false);
        }}
        className={cn(
          "flex cursor-pointer select-none items-center rounded-md bg-background py-1 border pr-2.5 capitalize transition-all hover:text-accent-foreground",
          {
            "text-black": open,
          },
        )}
      >
        <NumberBadge number={selected?.length} />
        <div className="shrink-0 text-sm">{name}</div>
        <div
          className={cn("w-4 transition-all", {
            "opacity-0": open,
          })}
        >
          <ChevronDown className="h-5 pt-1" />
        </div>
      </div>
    </div>
  );
};

export const Divider = () => {
  return <div className="w-full scale-x-150 border-b bg-gray-800" />;
};

interface NoResultsProps {
  readonly length: number;
}

export const NoResults = ({ length }: NoResultsProps) => {
  if (length === 0) {
    return <div className="p-2 text-gray-500">No filters were found</div>;
  }

  return null;
};

const haveSameElements = (arr1, arr2) => {
  return (
    arr1.length === arr2.length &&
    arr1.every((element) => arr2.includes(element))
  );
};

interface NumberBadgeProps {
  readonly number: number;
}
const NumberBadge = ({ number }: NumberBadgeProps) => {
  return (
    <div
      className={`text-center transition-all ${number === 0 ? "mx-0.5 scale-0" : "mx-3"} overflow-hidden px-1 border border-gray-400 rounded-full w-5 h-5 items-center text-xs`}
    >
      {number}
    </div>
  );
};

interface FilterCheckboxProps {
  readonly name: string;
  readonly selected: boolean;
  readonly onSelect: (...args: readonly any[]) => any;
}

export const FilterCheckbox = ({
  name,
  selected,
  onSelect,
}: FilterCheckboxProps) => {
  return (
    <div
      onClick={onSelect}
      className="flex flex-1 cursor-pointer select-none gap-2 px-4 py-2"
    >
      <input checked={selected} type="checkbox" readOnly />
      <div>{name || "[blank]"}</div>
    </div>
  );
};

interface FilterSearchProps {
  readonly text: string;
  readonly setText: (...args: readonly any[]) => any;
}

export const FilterSearch = ({ text, setText }: FilterSearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      tabIndex={0}
      onClick={() => {
        inputRef.current?.focus();
      }}
      onFocus={() => {
        inputRef.current?.focus();
      }}
      className="border-sm relative m-2 mb-0 flex cursor-text items-center overflow-hidden rounded-sm border-b-2 border-transparent bg-[#F0F2F3] p-1 focus-within:border-[#052A2D]"
    >
      <Search fontSize="small" className="text-[#212e2e]" />
      <input
        ref={inputRef}
        value={text}
        onChange={(e) => {
          setText(e.target.value);
        }}
        type="text"
        placeholder="Search"
        className="ml-1 bg-transparent text-[#3C5A5E] outline-none placeholder:text-[#3C5A5E]"
      />
      <Delete
        onClick={() => {
          setText("");
          inputRef.current?.focus();
        }}
        fontSize="inherit"
        className="absolute right-1 cursor-pointer text-accent-foreground"
      />
    </div>
  );
};
