import { useState } from "react";
import { DatePicker } from "./DatePicker";
interface DateRangePickerProps {
  readonly startDate: Date | undefined;
  readonly endDate: Date | undefined;
  readonly setStartDate: (date: Date) => void;
  readonly setEndDate: (date: Date) => void;
}

export const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: DateRangePickerProps) => {
  const [isStartPickerOpen, setIsStartPickerOpen] = useState<boolean>(false);
  const [isEndPickerOpen, setIsEndPickerOpen] = useState<boolean>(false);

  const handleStartDateSelect = (selectedDate: Date) => {
    setIsStartPickerOpen(false);
    if (startDate && selectedDate.getTime() !== startDate.getTime()) {
      setStartDate(selectedDate);
    }
    // Ensure end date is not before start date
    if (endDate && selectedDate > endDate) {
      setEndDate(selectedDate);
    }
  };

  const handleEndDateSelect = (selectedDate: Date) => {
    setIsEndPickerOpen(false);
    if (endDate && selectedDate.getTime() !== endDate.getTime()) {
      setEndDate(selectedDate);
    }

    // Ensure start date is not after end date
    if (startDate && selectedDate < startDate) {
      setStartDate(selectedDate);
    }
  };

  return (
    <div className="flex items-center gap-4">
      Start Date
      <DatePicker
        defaultDate={startDate}
        onSelect={handleStartDateSelect}
        isOpen={isStartPickerOpen}
        onOpenChange={setIsStartPickerOpen}
      />
      End Date
      <DatePicker
        defaultDate={endDate}
        onSelect={handleEndDateSelect}
        isOpen={isEndPickerOpen}
        onOpenChange={setIsEndPickerOpen}
      />
    </div>
  );
};
