import { Card, CardDescription, CardHeader, CardTitle } from "@/ui/card";
import { Tabs, TabsList, TabsTrigger } from "@/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import { useEffect, useRef, useState } from "react";
import { LogsTable } from "./LogsTable";
import { Separator } from "@/ui/separator";
import { DateRangePicker } from "./DateRangePicker";
import { SpreadsheetButton } from "./SpreadsheetButton";
import { sevenDaysAgo } from "@/types/types";
import { convertToStartOfDayUTC } from "@/types/types";
import { Filter } from "./Filter";
import useAllRdsQueries from "@/api/useAllRdsQueries";

export const ModuleTabs = () => {
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isScrolledLeft, setIsScrolledLeft] = useState<boolean>(true);
  const [isScrolledRight, setIsScrolledRight] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("rds_queries");

  const [startDate, setStartDate] = useState<Date>(sevenDaysAgo);
  const [endDate, setEndDate] = useState<Date>(new Date());

  const { logs, loading, error } = useAllRdsQueries(startDate, endDate);

  const checkHorizontalScrollPosition = () => {
    if (tabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
      setIsScrolledLeft(scrollLeft === 0);
      setIsScrolledRight(scrollLeft + clientWidth === scrollWidth);
    }
  };

  const scrollTabs = (direction: "left" | "right") => {
    if (tabsRef.current) {
      const scrollAmount = 448; // 2 tabs = 440px + 8px padding so 2 new tabs become visible
      if (direction === "left") {
        tabsRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else {
        tabsRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  // useEffect that checks the horizontal scroll position on h-scroll or resize
  useEffect(() => {
    if (tabsRef.current) {
      tabsRef.current.addEventListener("scroll", checkHorizontalScrollPosition);
      window.addEventListener("resize", checkHorizontalScrollPosition);
      checkHorizontalScrollPosition();
      return () => {
        tabsRef.current?.removeEventListener(
          "scroll",
          checkHorizontalScrollPosition,
        );
      };
    }
  }, []);

  const tabClassName = `flex-1 min-w-[220px] padding-2 rounded-none border-b-2 border-transparent duration-150 hover:border-coal inset-x-32
                        data-[state=active]:scale-102 data-[state=active]:border-black data-[state=active]:text-black data-[state=active]:bg-[#FBFBFB] data-[state=active]:drop-shadow-xl`;
  const uniqueAccounts: readonly string[] = Array.from(
    new Set(logs.map((log) => log.account)),
  );
  const uniqueResources: readonly string[] = Array.from(
    new Set(logs.map((log) => log.resource)),
  );
  const uniqueRequesters: readonly string[] = Array.from(
    new Set(logs.map((log) => log.requester)),
  );
  const uniqueApprovers: readonly string[] = Array.from(
    new Set(logs.map((log) => log.approver)),
  );

  const [accountFilter, setAccountFilter] = useState<readonly string[]>([]);
  const [resourceFilter, setResourceFilter] = useState<readonly string[]>([]);
  const [requesterFilter, setRequesterFilter] = useState<readonly string[]>([]);
  const [approverFilter, setApproverFilter] = useState<readonly string[]>([]);

  const filteredLogs = logs.filter((log) => {
    const matchesAccount =
      accountFilter.length === 0 || accountFilter.includes(log.account);
    const matchesResource =
      resourceFilter.length === 0 || resourceFilter.includes(log.resource);
    const matchesRequester =
      requesterFilter.length === 0 || requesterFilter.includes(log.requester);
    const matchesApprover =
      approverFilter.length === 0 || approverFilter.includes(log.approver);

    return (
      matchesAccount && matchesResource && matchesRequester && matchesApprover
    );
  });

  return (
    <div className="mt-2 relative">
      <Tabs
        value={selectedTab}
        onValueChange={setSelectedTab}
        className="w-full"
        defaultValue="rds_queries"
      >
        <button
          onClick={() => scrollTabs("left")}
          className={`z-40 h-9 absolute left-0 mx-2  padding-2 bg-[#FBFBFB] border-b-2 border-transparent hover:border-coal
          ${isScrolledLeft ? "cursor-not-allowed opacity-50 hover:border-transparent" : "cursor-pointer duration-150"}
          ${isScrolledLeft && isScrolledRight ? "hidden" : ""}`}
        >
          &lt;
        </button>
        <TabsList
          ref={tabsRef}
          className="clip-tabs flex overflow-x-auto no-scrollbar justify-around bg-[#FBFBFB]"
        >
          <TabsTrigger value="rds_queries" className={tabClassName}>
            RDS Queries
          </TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="aws_rds" className={tabClassName}>
            AWS RDS
          </TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="aws_secrets" className={tabClassName}>
            AWS Secrets
          </TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="aws_sqs" className={tabClassName}>
            AWS SQS
          </TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="private_bin" className={tabClassName}>
            Privatebin
          </TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="secret_migration" className={tabClassName}>
            Secret Migration
          </TabsTrigger>
          <Separator orientation="vertical" />
          <TabsTrigger value="streaming_platform" className={tabClassName}>
            Streaming Platform
          </TabsTrigger>
        </TabsList>
        <button
          type="button"
          onClick={() => scrollTabs("right")}
          className={`z-40 h-9 absolute right-0 mx-2 padding-2 -translate-y-[40px] transform bg-[#FBFBFB] border-b-2 border-transparent duration-150 hover:border-coal
            ${isScrolledRight ? "cursor-not-allowed opacity-50 hover:border-transparent" : "cursor-pointer duration-150"}
            ${isScrolledLeft && isScrolledRight ? "hidden" : ""}`}
        >
          &gt;
        </button>

        <div className="">
          <TabsContent value="rds_queries">
            <Card className="h-full mt-2">
              <CardHeader>
                <CardTitle className="flex items-center gap-8 mb-2 ml-4">
                  RDS Queries logs
                  <SpreadsheetButton
                    startDate={convertToStartOfDayUTC(startDate)}
                    endDate={convertToStartOfDayUTC(endDate)}
                  />
                </CardTitle>
                <CardDescription className="mb-2 ml-4">
                  <div className="flex items-center justify-between gap-4">
                    <DateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                    <div className="flex gap-1">
                      <Filter
                        name="account"
                        filterArr={uniqueAccounts}
                        setFilters={setAccountFilter}
                      />
                      <Filter
                        name="resource"
                        filterArr={uniqueResources}
                        setFilters={setResourceFilter}
                      />
                      <Filter
                        name="requester"
                        filterArr={uniqueRequesters}
                        setFilters={setRequesterFilter}
                      />
                      <Filter
                        name="approver"
                        filterArr={uniqueApprovers}
                        setFilters={setApproverFilter}
                      />
                    </div>
                  </div>
                </CardDescription>
                {error ? (
                  <div className="flex justify-center">
                    <p className="ml-4 mt-8">
                      Error loading data: {error.message}
                    </p>
                  </div>
                ) : loading ? (
                  <div className="flex justify-center">
                    <p className="ml-4 mt-8">Loading...</p>
                  </div>
                ) : logs.length ? (
                  <div className="h-[calc(100vh-300px)] overflow-auto mb-4">
                    <LogsTable data={filteredLogs ?? []} />
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <p className="ml-4 mt-8">No data available.</p>
                  </div>
                )}
              </CardHeader>
            </Card>
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );
};
