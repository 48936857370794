import { BookOpenText, LogOut, ScrollText } from "lucide-react";
import { Tooltip } from "@nexus/lib-react/dist/core/Tooltip";
import { useAuth } from "../auth";
import { NavLink } from "react-router-dom";
import React from "react";

interface ToolButtonProps {
  readonly children: React.ReactNode;
  readonly onClick?: () => void;
  readonly tooltipContent: string;
}

const ToolButton = ({ children, onClick, tooltipContent }: ToolButtonProps) => {
  return (
    <Tooltip content={tooltipContent} placement="left" trigger="mouseenter">
      <div
        onClick={onClick}
        className="drop-shadow-olx m-1 rounded-md border bg-white p-2 text-coal transition-all duration-150 hover:ring-gray-700 hover:ring hover:ring-inset"
      >
        {children}
      </div>
    </Tooltip>
  );
};

const DocumentationButton = () => {
  return (
    <a
      className=""
      target="_blank"
      rel="noopener noreferrer"
      href="https://naspersclassifieds.atlassian.net/wiki/spaces/CORETECH/pages/57534710077/Cora"
    >
      <BookOpenText />
    </a>
  );
};

const LogsButton = () => {
  return (
    <NavLink to="/home/logs">
      <ScrollText />
    </NavLink>
  );
};

export const SideBar = () => {
  const { canLogout, logout } = useAuth();
  return (
    <div className="fixed top-[72px] flex h-[calc(100%-72px)] w-12 flex-col">
      <div className="flex justify-between h-full bg-[#F5F5F5] max-sm:border-b sm:flex-col sm:shadow-lg sm:shadow-gray-400">
        <div>
          <ToolButton onClick={undefined} tooltipContent="Logs">
            <LogsButton />
          </ToolButton>
        </div>
        <div className="justify-end">
          <ToolButton onClick={undefined} tooltipContent="Documentation">
            <DocumentationButton />
          </ToolButton>
          {canLogout && (
            <ToolButton tooltipContent="Sign out" onClick={() => logout()}>
              <LogOut />
            </ToolButton>
          )}
        </div>
      </div>
    </div>
  );
};
