import { PropsWithChildren } from "react";
import { Provider } from "@nexus/lib-react/dist/core/Provider";
import { theme } from "@nexus/lib-react/dist/theme/CoreTheme";
import { Toaster } from "react-hot-toast";
import "./App.css";
import { Toaster as SonnerToaster } from "sonner";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { DefaultErrorBoundary } from "./components/DefaultErrorBoundary";
import { AuthProvider, Login, LoginDispatcher, Logout } from "./auth";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { Layout } from "./pages/Layout";
import { RequiredAuth } from "./router/RequiredAuth";
import { LoginCallbackDispatcher } from "./auth/LoginCallbackDispatcher";
import { LogsPage } from "./pages/LogsPage";

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      retry: () => false,
      staleTime: 1000 * 60,
    },
  },
});

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    ErrorBoundary: DefaultErrorBoundary,
    children: [
      {
        element: <Login />,
        path: "/",
      },
      {
        element: <LoginDispatcher />,
        path: "/login/provider/:provider",
      },
      {
        element: <LoginCallbackDispatcher />,
        path: "/login/callback/:provider",
      },
      {
        element: <Logout />,
        path: "/logout",
      },
      {
        element: <RequiredAuth />,
        // element: <Layout />,
        path: "/home",
        children: [
          {
            element: <Layout />,
            children: [
              {
                path: "",
                element: <Navigate to="logs" replace />,
                index: true,
              },
              {
                path: "logs",
                element: <LogsPage />,
              },
            ],
          },
        ],
      },
    ],
  },
] as const);

export const MainWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Provider theme={theme}>
      <Toaster />
      <SonnerToaster richColors />
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </Provider>
  );
};

function App() {
  return (
    <MainWrapper>
      <RouterProvider router={router} />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </MainWrapper>
  );
}

export default App;
