import { getToken } from "@/auth";
import {
  convertToEndOfDayUTC,
  convertToStartOfDayUTC,
  LogItem,
} from "@/types/types";
import { useState, useEffect } from "react";

const fetchAllRdsQueries = async (startDate: Date, endDate: Date) => {
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  const baseURL = BASE_URL + "/api";

  try {
    const queryParams = new URLSearchParams({
      from: parseInt(
        (convertToStartOfDayUTC(startDate).getTime() / 1000).toFixed(0),
      ).toString(),
      to: parseInt(
        (convertToEndOfDayUTC(endDate).getTime() / 1000).toFixed(0),
      ).toString(),
    });

    const headers: Record<string, string> = {};

    var token = getToken();
    if (!token) {
      token = "";
    }

    headers["Authorization"] = "Bearer " + token;

    const response = await fetch(
      baseURL + `/logs/all-rds-queries?${queryParams.toString()}`,
      {
        method: "GET",
        headers: headers,
      },
    );

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

interface UseDateRangeFetchResult {
  readonly logs: readonly LogItem[];
  readonly loading: boolean;
  readonly error: Error | null;
}

const useAllRdsQueries = (
  startDate: Date,
  endDate: Date,
): UseDateRangeFetchResult => {
  const [logs, setLogs] = useState<readonly LogItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!startDate || !endDate) return;

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetchAllRdsQueries(startDate, endDate);
        setLogs(response.items);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  return { logs, loading, error };
};

export default useAllRdsQueries;
